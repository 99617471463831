import React, {useState, useEffect} from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";

// Fonction pour générer le code-barres
const generateBarcode = (value) => {
  let canvas = document.createElement("canvas");
  JsBarcode(canvas, value, {
    format: "CODE128",
    displayValue: false,
    height: 80,  // Double the height
    width: 4,   // Double the width
  });
  return canvas.toDataURL();
};

// Définition des styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    flexDirection: "column",
  },
  cblogo: {
    fontFamily: "Museo100",
    letterSpacing: 4,
    textAlign: "center",
    fontSize: 18,
    marginBottom: 20,
  },
  infoContainer: {  // Conteneur pour chaque information
    width: "100%",
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  mainInfoContainer: {  
    width: "100%",
    padding: 10,
    marginBottom: 10,
    borderWidth: 3,
    borderColor: "#000",
    display: "flex",
    justifyContent: "center", // Center content vertically
    alignItems: "center", // Center content horizontally
    textAlign: "center",
  },
  infoTitle: {  
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },
  bigInfo: { 
    fontSize: 30,
    fontFamily: "Museo500",
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },
  giantInfo: { 
    fontFamily: "Museo500",
    fontSize: 120,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },
  location: {  
    fontFamily: "Museo500",
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },
  infoValue: {  
    fontSize: 24,
  },
  label: {
    fontSize: 20,  // Increase font size
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  barcodeContainer: { 
    width: "100%",
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Ensures alignment in case of height differences
    marginBottom: 20,
    textAlign: "center", // Ensures text stays centered if needed
  },
  barcode: {
    width: 500,
    height: 120,
  },
  summaryTable: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between', // Distribuer l'espace entre les colonnes
  },
  summaryRow: {
    width: '16%', // Ajuster la largeur des colonnes pour éviter le retour à la ligne
    marginBottom: 5,
    alignItems: 'center',  // Centrer verticalement le contenu
  },
  summaryLabel: {
    fontSize: 10,  // Réduire la taille de la police
    textAlign: 'center', // Centrer le texte horizontalement
  },
  summaryValue: {
    fontSize: 10,  // Réduire la taille de la police
    textAlign: 'center', // Centrer le texte horizontalement
  }
});





const A4Labels = ({ type, location, zone, rack, bay, shelf, bin, fullLabel }) => {
  const barcodeData = generateBarcode(fullLabel);
  const [establishmentsData, setEstablishmentsData] = useState(null);




    // Fetch the JSON file from S3 when the component mounts
    useEffect(() => {
      const fetchData = async () => {
        try {
          const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/establishments.json`;
          const response = await fetch(fetchUrl);
          if (!response.ok) {
            throw new Error('Failed to fetch search data');
          }
          const jsonData = await response.json();
          setEstablishmentsData(jsonData); // Set loaded JSON data
        } catch (error) {
          console.error('Error loading JSON data:', error);
        }
      };
  
      fetchData();
    }, []);




  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.cblogo}>Ceysson & Bénétière</Text>

        {type=="location" ? (<View style={[styles.mainInfoContainer, { height: "50%"}]}>
          <Text style={styles.infoTitle}>FACILITY</Text>
          <Text style={styles.bigInfo}>{establishmentsData?.find(item => item.id == location)?.code || location}</Text>
          <Text style={{textAlign: "center", fontFamily: "Museo500"}}>{establishmentsData?.find(item => item.id == location)?.name || location}</Text>
     
        </View>):( <View>
          <Text style={styles.location}>{establishmentsData?.find(item => item.id == location)?.code || location}</Text>
          <Text style={{textAlign: "center", fontFamily: "Museo500", marginBottom: "30px"}}>{establishmentsData?.find(item => item.id == location)?.name || location}</Text>
          </View>)}

      
        {type=="zone" ? (<View style={[styles.mainInfoContainer, { height: "43%"}]}>
          <Text style={styles.bigInfo}>ZONE</Text>
          <Text style={styles.giantInfo}>{zone}</Text> 
        </View>): zone ? ( <View style={styles.infoContainer}>
          <Text style={styles.bigInfo}>ZONE {zone}</Text>
        </View>):("")}


        {type=="rack" ? (<View style={[styles.mainInfoContainer, { height: "35%"}]}>
          <Text style={styles.bigInfo}>RACK</Text>
          <Text style={styles.giantInfo}>{rack}</Text>
        </View>): rack ? ( <View style={styles.infoContainer}>
          <Text style={styles.bigInfo}>RACK {rack}</Text>
        </View>):("")}


        {type=="bay" ? (<View style={[styles.mainInfoContainer, { height: "27%"}]}>
          <Text style={styles.bigInfo}>BAY</Text>
          <Text style={styles.giantInfo}>{bay}</Text>
        </View>): bay ? ( <View style={styles.infoContainer}>
          <Text style={styles.bigInfo}>BAY {bay}</Text>
        </View>):("")}


        {type=="shelf" ? (<View style={[styles.mainInfoContainer, { height: "20%"}]}>
          <Text style={styles.bigInfo}>SHELF {shelf}</Text>
        </View>): shelf ? ( <View style={styles.infoContainer}>
          <Text style={styles.bigInfo}>SHELF {shelf}</Text>
        </View>):("")}


        <View style={styles.infoContainer}>
          <View style={styles.barcodeContainer}> {/* Englober l'image dans un View */}
            <Image src={barcodeData} style={styles.barcode} />
          </View>
          <Text style={styles.label}>{fullLabel}</Text>
        </View>


        <View style={styles.infoContainer}>
  <Text style={styles.infoTitle}>Barcode description</Text>
  <View style={styles.summaryTable}> {/* Nouveau style pour le tableau */}
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Location</Text>
      <Text style={styles.summaryValue}>
      {establishmentsData?.find(item => item.id === location)?.name || location}
    </Text> 
    </View>
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Zone</Text>
      <Text style={styles.summaryValue}>{zone}</Text>
    </View>
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Rack</Text>
      <Text style={styles.summaryValue}>{rack}</Text>
    </View>
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Bay</Text>
      <Text style={styles.summaryValue}>{bay}</Text>
    </View>
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Shelf</Text>
      <Text style={styles.summaryValue}>{shelf}</Text>
    </View>
    <View style={styles.summaryRow}>
      <Text style={styles.summaryLabel}>Bin</Text>
      <Text style={styles.summaryValue}>{bin}</Text>
    </View>
  </View>
</View>

      </Page>
    </Document>
  );
};

export default A4Labels;