import React, { useEffect, useState, useMemo, useRef, memo, Suspense, lazy, useCallback } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Link, useParams } from "react-router-dom";
import EditOffCanvas from "../OffCanvas/EditOffCanvas";
import Loading from "../Loading/Loading";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  Popover,
  Portal,
  Grid,
} from "@mui/material";
import "./DetailView.css";

import ListItemText from "@mui/material/ListItemText";
import {loadItem, updateItem} from '../../api'; 
import useModelLoader from '../../hooks/useModelLoader';
import EditView from './EditView';
import CustomDispatch from './CustomDispatch';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ModalButtonsAddTo from '../Modal/ModalButtonsAddTo';
import { useSelectOptions } from '../../hooks/useSelectOptions';
import { getIconComponent } from '../IconManager/IconManager'; 
import DynamicComponentLoader from '../DynamicComponent/DynamicComponent'; // Ensure correct import path
import PdfButtons from "../PDF/PdfButtons";
import { usePermissions } from '../../contexts/PermissionsContext';
import SavePublicDataButton from "../CacheSaving/SavePublicDataButton";
import SavePrivateDataButton from "../CacheSaving/SavePrivateDataButton";
import ErasePublicDataButton from "../CacheSaving/ErasePublicDataButton";
import { usePopover, imagePopover } from "../Popover/Popover";
import { checkPermissionsDetail } from "../../utils/checkPermissions";
import { unslugify } from "../../utils/utils";

import GeneralHelpModal from "../Help/GeneralHelpModal";


function DetailView() {

  dayjs.extend(utc);

  const { section, id } = useParams();
  const [fieldData, setFieldData] = useState([]);
  const userId = localStorage.getItem('userId');
  const popoverRef = useRef(null);



  const queryClient = useQueryClient()
  const { isPending, isError, data, error, refetch } = useQuery({
    queryKey: ['items', section, { id: id }],
    queryFn: () => loadItem(section, id)
  });


  
  const item_data = data;
  
  const [model, setModel] = useState(null);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [tabsLoaded, setTabsLoaded] = useState(false); // Define tabsLoaded here
  const [anchorEl, setAnchorEl] = useState(null);

  //pour card listS3
  const [imgOrder, setImgOrder] = useState(null);


  const Photos = lazy(() => import("../Photos/Photos"));
  const Documents = lazy(() =>
    import("../Documents/Documents")
  );
  const TableViewAsso = lazy(() =>
  import("../ListView/TableViewAsso")
);



const params = useParams();

const handleFieldUpdate = async (field, newValue) => {
  console.log(newValue);
  let valueToSend = newValue;

  if (field.fieldType === 'datetime' && dayjs.isDayjs(newValue)) {
    // Convert to UTC and then format in MySQL datetime format: 'YYYY-MM-DD HH:mm:ss'
    valueToSend = newValue.utc().format('YYYY-MM-DD HH:mm:ss');
    console.log('formatted date', valueToSend);
  }
  

  console.log(valueToSend);
  mutation.mutate({ section, fieldKey: field.key, id, newValue: valueToSend });
};



const mutation = useMutation({
  mutationFn: (args) => updateItem(args.section, args.id, args.fieldKey, args.newValue),
  onSuccess: (data, variables) => {
    queryClient.setQueryData(['items', section, { id: id }], (oldData) => {
        const updatedValue = variables.newValue === "SetNull" ? null : variables.newValue;

        return {
            ...oldData,
            [variables.fieldKey]: updatedValue,
            ['last_updated_by']: userId
        };
    });
},
  onError: (error) => {
    // Handle error
    console.error("Error posting data:", error);
  },
});

const queryParamsValueAsso = useMemo(() => {
  return {from_model: section, from_id: id, order_by: 'created_at'};
}, [model, id]);



const { modelData } = useModelLoader(section);




const selectMappings = useSelectOptions(modelData ? modelData.fields : []);
// Fetching select mappings for the given model fields, always including 'last_updated_by' from users.json by default
//const selectMappings = useSelectOptions(modelData ? [...modelData.fields, { key: 'last_updated_by', selectFile: 'dynamic_json/users.json', fieldType: 'select' }] : []);


const [activeTab, setActiveTab] = useState(0);


  const handleEditClick = (field, conditionnalFileLoad) => {
    setEditingField(field, conditionnalFileLoad);
    setShowOffCanvas(true);
  };

  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
  };

  const handleRefresh = () => {
    refetch();
  };
  
  const handleCloseModalrefresh = () => {
    handleRefresh();
  };



  useEffect(() => {
    setTabsLoaded(true);
}, []);


useEffect(() => {
  if (item_data && item_data.img_sort_order) {
    setImgOrder(item_data.img_sort_order);
  }
}, [item_data]);

const handleImgOrderChange = useCallback((newOrder) => {
  setImgOrder(newOrder);
  console.log('newOrder detail', newOrder);
}, []);


useEffect(() => {
  if (modelData && modelData.tabs && modelData.tabs.length > 0) {
    setActiveTab(modelData.tabs[0].id);
  }
}, [modelData]);





const bucketPhotos = modelData?.bucketPhotos || "cb-business-manager";


function renderTabContent(tab, item_data, params, queryParamsValueAsso, handleUpdate, handleRefresh) {
  switch (tab.id) {
    case "main_info":
      return <EditView section={section} item_data={item_data} modelData={modelData} handleEditClick={handleEditClick} />;
    case "photos":
      return <Photos bucket={bucketPhotos} params={params} imgOrder={imgOrder} onOrderChange={handleImgOrderChange} handleUpdate={handleUpdate} />;
    case "documents":
      return <Documents params={params} />;
      case "kyc":
        return <Documents params={params} kindOfDocs="kyc" />;
    case "history":
      return <TableViewAsso section={`history_${section}`} modelfile="history" queryParams={queryParamsValueAsso} />;
    default:
      if (tab.dynamic) {
        return <DynamicComponentLoader config={{ component: tab.component }} item_data={item_data} section={section} />;
      }
      else if (tab.custom) {
        return <CustomDispatch item_data={item_data} section={section} tab={tab} queryParams={queryParamsValueAsso} />;
      } else {
        let associationType = tab.type_of_assoc || "";
        let table = tab.table || tab.id;
        if (tab.custom_value && tab.custom_value.table) {
          table = tab.custom_value.table;
        }
        return (
          <TableViewAsso 
            section={table} 
            modelfile={tab.id} 
            association={associationType} 
            queryParams={queryParamsValueAsso} 
            fromTable={section} 
            inBetweenTable={tab.in_between_table}
            custom_value={tab?.custom_value}
            custom_foreign_key={tab?.custom_foreign_key}
          />
        );
      }
  }
}

let pageTitle = modelData?.detailsTitle || `${unslugify(section)} `; 
pageTitle += ` - ${item_data?.name} (${item_data?.id})`;
document.title = pageTitle


const { permissions } = usePermissions();

console.log("detailrender");

const PopoverContent = useMemo(() => (
  <Portal node={popoverRef.current}>
    <Popover
      open
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setAnchorEl(null)}
    >
      <Typography component="img" src={`https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${item_data?.["main_image"]}-900-600.jpg`} alt={item_data?.main_image} />
    </Popover>
  </Portal>
), [anchorEl, bucketPhotos, id, section]);



if (!item_data || !modelData)
return (
  <div>
    <Loading />
  </div>
);


const storedData = localStorage.getItem("StoredUserDataFromDb");
const userData = JSON.parse(storedData);
const employeeId = userData?.employees_id;


console.log("userData", userData)

const { canModify, canViewDetail, canViewGlobal, message_permission="You need permissions for this section" } = checkPermissionsDetail(
    permissions, modelData, section, item_data, userId, employeeId
);

if (!canViewGlobal && !canModify && !canViewDetail) {
    return (<div><br />{message_permission}</div>);
}



/*
let canViewGlobal =  false;
let canModify =  false;
let canViewDetail =  false;


if(modelData?.permissions?.allow_master_pass && permissions.includes('master_pass_'+section)){
  canModify= true;
}
else{
if(modelData?.permissions?.permissions_to_view || modelData?.permissions?.permissions_to_view_detail){
  canViewDetail = permissions.includes('can_view_'+section);
  if(modelData?.permissions?.permissions_to_view_detail){
    canViewDetail = permissions.includes('can_view_detail_'+section);
  }
  canModify = permissions.includes('can_modify_'+section);
  canViewDetail = permissions.includes('can_view_detail_'+section);
  canViewGlobal = permissions.includes(modelData?.permissions?.permissions_to_view_global);
 if(!canViewGlobal && ! canModify && ! canViewDetail){
   return (<div><br/>You need permissions for this section</div>);
 }
}

  if (modelData?.permissions?.permissions_to_modify) {
    canModify = permissions.includes(`can_modify_${section}`);
  } else {
    canModify = true;
  }


if (modelData?.permissions?.permissions_limited_to_some_id) {
  canModify = false;
  const storedData = localStorage.getItem("StoredUserDataFromDb");
const userId = localStorage.getItem('userId');

// Parse the JSON string into an object
const userData = JSON.parse(storedData);

// Access employees_id directly
const employeeId = userData?.employees_id;

  if(section=="users"){
    canModify = userId == item_data.users_id || userId == item_data.id;
  }
  else if(section=="employees"){
    canModify = item_data.id == employeeId || userId == item_data.users_id;
    if(!canModify){
      return("you don't manage this employee")
    }
  }
}
}*/


const canModifySection = permissions.includes('can_modify_' + section) || !modelData?.permissions?.permissions_to_modify;

const shouldRenderButtons = modelData.addButtons && canModifySection;


  return (
    <div>
       {item_data.is_deleted ? (<Alert sx={{my:5}}variant="filled" severity="error">
       <AlertTitle>ALERT</AlertTitle>
       This file is deleted. It is still recoverable but it wont appear anywhere in the table or in your search. Reactivate it if needed.
</Alert>) : ("")}
<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>

      <Box sx={{ width: { xs: '100%', md: 250 }, mb: { xs: 2, md: 0 }}}>
      <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
      <GeneralHelpModal style="large" section={section} view="detail" modelData={modelData}/>
      </Box>

      {(modelData.addButtons || modelData.pdfDetailsButtons) && (
      <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
        {shouldRenderButtons && (
<ModalButtonsAddTo buttonNames={modelData.addButtons} section={section} handleRefresh={handleRefresh} selectedRowIds={[id]} />
)}
        {modelData.pdfDetailsButtons && (
<PdfButtons buttonNames={modelData.pdfDetailsButtons} itemData={item_data} section={section} 
selectedRowIds={[id]}  />
)}
</Box>)}


  {modelData?.savePrivateDataButton=="details" && (
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePrivateDataButton id={id} section={section} single="true" data={modelData?.savePrivateDataButton} />
  </Box>)}



  {modelData?.savePublicDataButton &&(
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePublicDataButton id={id} section={section} single="true" data={modelData?.savePublicDataButton} />
  </Box>)}


  {modelData?.savePublicDataButtonDetailOnly &&(
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePublicDataButton id={id} section={section} single="true" data={modelData?.savePublicDataButtonDetailOnly} />
  </Box>)}


  {modelData?.erasePublicDataButton &&(
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <ErasePublicDataButton id={id} section={section} single="true" data={modelData?.erasePublicDataButton} />
  </Box>)}





<Card sx={{ minWidth: '220px'}}>
  <CardContent sx={{ padding: '16px' }}>
    <Typography variant="h5" sx={{ fontSize: '1rem', marginBottom: '1rem' }}>
      {section} details
    </Typography>
    <Link to={`../${section}/table`} style={{ fontSize: '0.8rem', marginBottom: '1rem', display: 'block' }}>
      Back to {section} list
    </Link>
    <List sx={{ fontSize: '0.7rem', padding: 0 }}>
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Id" secondary={item_data.id || "No info"} />
      </ListItem>
      {item_data.slug && (  <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Slug" secondary={item_data.slug || "No info"} />
      </ListItem>)}
      {item_data.main_image && (  <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Main image" secondary={item_data.main_image || "No info"} />
      </ListItem>)}
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Created at" secondary={item_data.created_at || "No info"} />
      </ListItem>
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Updated at" secondary={item_data.updated_at || "No info"} />
      </ListItem>
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText 
          primary="Created by" 
          secondary={item_data.created_by ? selectMappings.last_updated_by?.[item_data.created_by] || item_data.created_by : item_data.created_by } 
        />
      </ListItem>
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText 
          primary="Last updated by" 
          secondary={item_data.last_updated_by ? selectMappings.last_updated_by?.[item_data.last_updated_by] || item_data.last_updated_by  : item_data.last_updated_by } 
        />
      </ListItem>
      <ListItem sx={{ padding: '0 0' }}>
        <ListItemText primary="Name" secondary={item_data.name || "No info"} />
      </ListItem>
    </List>
  </CardContent>
</Card>


        <Card sx={{ mt: '2rem' }} >

                


      
                    <Box sx={{ position: 'relative' }}>
{modelData?.noImage ==true ? ("") : item_data?.main_image == null || item_data?.main_image == "" ? (
    <Typography variant="h6" sx={{ p: 2 }}>
      No image
    </Typography>
  ) : (
  <CardMedia
    component="img"
    image={`https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${item_data["main_image"]}-450-300.jpg`}
    alt={item_data.main_image}
    onMouseOver={(event) => setAnchorEl(event.currentTarget)}
  />)}

 {anchorEl && PopoverContent}
</Box>


</Card>

      </Box>


      {/* Flexible card that grows, full width on xs screens */}
      <Box className="BoxDetailView">
      <Card>
          <CardContent>
          <Tabs
      className="tabsDetailView"
      value={activeTab}
      onChange={(event, newValue) => setActiveTab(newValue)}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="scrollable force tabs example"
    >
      {tabsLoaded &&
        modelData.tabs.map((tab) => {
          let tabTitle = tab.title;
          const iconName = tab.icon || `${tab.id}Icon`;
        
          const IconComponent = getIconComponent(iconName);
        
          return (
            <Tooltip title={tab.help || tab.title} key={tab.id}>
              <Tab
                value={tab.id}
                icon={IconComponent || null}
                label={IconComponent ? "" : tabTitle}
              />
            </Tooltip>
          );
        })
      }
    </Tabs>
    <Suspense fallback={<div><Loading /></div>}>
  {modelData.tabs.map(tab => {
    if (activeTab === tab.id) {
      return (
        <div key={tab.id} value={tab.id}>
          {renderTabContent(tab, item_data, params, queryParamsValueAsso, handleFieldUpdate, handleRefresh)}
        </div>
      );
    }
    return null;
  })}
</Suspense>
          </CardContent>
        </Card>
      </Box>
    </Box>
  
        {editingField && (
          <EditOffCanvas
            key={editingField.key}
            show={showOffCanvas}
            placement={"end"}
            handleClose={handleCloseOffCanvas}
            field={editingField}
            conditionnalFileLoad={item_data[editingField?.conditionnalFileLoad]}
            value={item_data[editingField.key]}
            handleUpdate={handleFieldUpdate}
            id={id}
            section={section}
            />
        )}
    </div>
  );
}

export default React.memo(DetailView);
