import React from "react";
import { Typography, Box, Button, Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { loadItems } from "../../api";
import Grid from '@mui/material/Grid2';
import CountItems from "./Widgets/CountItems";
import SimpleTableDashboard from "./SimpleTableGenericForWidget.js/SimpleTableDashboard";
import HoursByCity from "./Widgets/HoursByCity";
import RecentHistory from "./RecentHistory";
import GlobalHelpModal from "../Help/GlobalHelpModal";


const Home = () => {
  const queryParams = {limit: 500};
  const fieldsToFetchArtworks = "id,name, created_at, updated_at, created_by, last_updated_by, main_image";
  const fieldsToFetchContacts = "id,name, created_at, updated_at, created_by, last_updated_by";
  const userId = localStorage.getItem('userId');

  const queryArtworks = useQuery({
    queryKey: ["artworksItems", "artworks", queryParams, fieldsToFetchArtworks, ""],
    queryFn: () => loadItems( "artworks", queryParams, fieldsToFetchArtworks, ""),
    keepPreviousData: true,
  });


  const queryContacts = useQuery({
    queryKey: ["contactsItems",  "contacts", queryParams, fieldsToFetchContacts, ""],
    queryFn: () => loadItems( "contacts", queryParams, fieldsToFetchContacts, ""),
    keepPreviousData: true,
  });


  const queryArtpartners = useQuery({
    queryKey: ["artpartnersItems",  "artpartners", queryParams, fieldsToFetchContacts, ""],
    queryFn: () => loadItems( "artpartners", queryParams, fieldsToFetchContacts, ""),
    keepPreviousData: true,
  });

  const artworksItems = queryArtworks.data || [];
  const contactsItems = queryContacts.data || [];
  const artpartnersItems = queryArtpartners.data || [];



  

  // Group the dashboard sections
  const GlobalActivitySection = () => (
    <>
    <Grid size={{ xs: 12, md:10 }}>
    <Grid container spacing={2}>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artworks" 
          title="Last Updated Artworks" 
          data={artworksItems} 
          sort_field="updated_at"
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artworks" 
          title="Last Added Artworks" 
          data={artworksItems} 
          sort_field="created_at"
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="contacts" 
          title="Last Updated Contacts" 
          data={contactsItems} 
          sort_field="updated_at"
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artpartners" 
          title="Last Updated Artpartners" 
          data={artpartnersItems} 
          sort_field="updated_at"
        />
      </Grid>
      </Grid>
      </Grid>
      <Grid  size={{ xs: 12, md: 2 }}>
        <Grid container spacing={2}>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Added Yesterday" 
              data={artworksItems} 
              section ="artworks"
              count_when="yesterday" 
              added_or_updated="added"
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Added Today" 
              data={artworksItems} 
              section ="artworks"
              count_when="today" 
              added_or_updated="added"
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Updated Yesterday" 
              data={artworksItems} 
              section ="artworks"
              count_when="yesterday" 
              added_or_updated="updated"
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Updated Today" 
              data={artworksItems} 
              section ="artworks"
              count_when="today" 
              added_or_updated="updated"
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artpartners Updated Today" 
              data={artpartnersItems} 
              count_when="today" 
              added_or_updated="updated"
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Contacts Updated Today" 
              data={contactsItems} 
              count_when="today" 
              added_or_updated="updated"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );





  const PersonalActivitySection = () => (
<>
        <Grid  size={{ xs: 12, md:10 }}>
        <Grid container spacing={2}>
        <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artworks" 
          title="Last Updated Artworks by Me" 
          data={artworksItems} 
          sort_field="updated_at" 
          by_me={userId}
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artworks" 
          title="Last Added Artworks by Me" 
          data={artworksItems} 
          sort_field="created_at" 
          by_me={userId}
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="contacts" 
          title="Last Updated Contacts by Me" 
          data={contactsItems} 
          sort_field="updated_at" 
          by_me={userId}
        />
      </Grid>
      <Grid  size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section="artpartners" 
          title="Last Updated Artpartners by Me" 
          data={artpartnersItems} 
          sort_field="updated_at" 
          by_me={userId}
        />
      </Grid>
      </Grid>
      </Grid>
      
      <Grid  size={{ xs: 12, md: 2 }}>
        <Grid container spacing={2}>
        <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Added Yesterday by me" 
              data={artworksItems} 
              count_when="yesterday" 
              added_or_updated="added" 
              section ="artworks"
              by_me={userId}
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Added Today by me" 
              data={artworksItems} 
              count_when="today" 
              section ="artworks"
              added_or_updated="added" 
              by_me={userId}
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Modified Yesterday by me" 
              data={artworksItems} 
              count_when="yesterday" 
              section="artworks"
              added_or_updated="updated" 
              by_me={userId}
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artworks Modified Today by me" 
              data={artworksItems} 
              count_when="today" 
              section="artworks"
              added_or_updated="updated" 
              by_me={userId}
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Artpartners Updated Today by me" 
              data={artpartnersItems} 
              count_when="today" 
              section="artpartners"
              added_or_updated="updated" 
              by_me={userId}
            />
          </Grid>
          <Grid  size={{ xs: 12}}>
            <CountItems 
              title="Contacts Updated Today by me" 
              data={contactsItems} 
              count_when="today" 
              section ="contacts"
              added_or_updated="updated" 
              by_me={userId}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );


  /*

    <Button variant="contained" color="primary">Primary</Button>


      <Button variant="contained" color="secondary">Secondary</Button>
  */

  return (
    <Box sx={{ padding: 4 }}>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between' // Key change here
    }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> 
  <Typography variant="body2"> {/* Ou "caption" */}
    First time here? Let's get some help
  </Typography>
  <GlobalHelpModal style="normal" section="start" />
</Box>
    </Box>
     


      <Grid  sx={{ xs:12}}>
          
              <Grid container spacing={4}>
              <Grid  size={{ xs: 4}}>
        <HoursByCity city="New York" />
      </Grid>
      <Grid  size={{ xs: 4}}>
        <HoursByCity city="La Chaulme" />
      </Grid>
      <Grid  size={{ xs: 4}}>
        <HoursByCity city="Tokyo" />
      </Grid>
           
              </Grid>
            </Grid>
<br/>


  

            <Grid  sx={{ xs:12}}>
              <Typography variant="h5" gutterBottom>Global Activity</Typography>
              <Grid container spacing={4}>
                <GlobalActivitySection />
              </Grid>
            </Grid>


            <Grid  sx={{ xs:12}}>
              <Typography variant="h5" gutterBottom>My Activity</Typography>
              <Grid container spacing={4}>
                <PersonalActivitySection />
              </Grid>
            </Grid>


            <Grid  sx={{ xs:12}}>
              <Grid  style={{width:'100%'}}container spacing={4}>
                <RecentHistory />
              </Grid>
            </Grid>



        

    </Box>
  );
};

export default Home;
