import { Icon } from '@iconify/react';
import { ReactComponent as ExhibitionIcon } from '../../icons/exhibition.svg';
import { ReactComponent as ArtworkIcon } from '../../icons/artwork.svg';
import { ReactComponent as ArtistIcon } from '../../icons/artist.svg';
import { ReactComponent as ArtStorageIcon } from '../../icons/artstorage.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { ReactComponent as Incoming_consignmentsIcon } from '../../icons/incoming_consignments.svg';
import { ReactComponent as Outgoing_consignmentsIcon } from '../../icons/outgoing_consignments.svg';
import { ReactComponent as DeliveriesIcon } from '../../icons/delivery.svg';
import { ReactComponent as AddressesIcon } from '../../icons/addresses.svg';
import { ReactComponent as AttachedImagesIcon } from '../../icons/attached_images.svg';
import { ReactComponent as AttachedDocsIcon } from '../../icons/attached_documents.svg';
import { ReactComponent as FleetIcon } from '../../icons/fleet.svg';
import { ReactComponent as IPayIcon } from '../../icons/money_down.svg';
import { ReactComponent as OPayIcon } from '../../icons/money_up.svg';
import { ReactComponent as JournalistsIcon } from '../../icons/journalists_2.svg';
import { ReactComponent as InterestManyIcon } from '../../icons/interest_many.svg';
import { ReactComponent as InterestOneIcon } from '../../icons/interest_one.svg';
import { ReactComponent as CollectionOutIcon } from '../../icons/collection_outside.svg';
import { ReactComponent as CollectionIcon } from '../../icons/collection.svg';
import { ReactComponent as Artworks_createdIcon } from '../../icons/artworks_created.svg';
import { ReactComponent as Artworks_updatedIcon } from '../../icons/artworks_updated.svg';
import { ReactComponent as Contacts_updatedIcon } from '../../icons/contacts_updated.svg';
import { ReactComponent as Contacts_createdIcon } from '../../icons/contacts_created.svg';
import { ReactComponent as Artpartners_updatedIcon} from '../../icons/artpartners_updated.svg';
import { ReactComponent as Artpartners_createdIcon} from '../../icons/artpartners_created.svg';
import { ReactComponent as Artworks_soldIcon} from '../../icons/artworks_sold.svg';
import { ReactComponent as ReservationsIcon} from '../../icons/reservations.svg';
import { ReactComponent as Artworks_favoritesIcon} from '../../icons/artworks_favorites.svg';
import { ReactComponent as Artworks_collectionIcon} from '../../icons/artworks_collection.svg';
import { ReactComponent as SalesAndAcquistionsIcon} from '../../icons/cartupdown.svg';
import { ReactComponent as Publishing_companiesIcon} from '../../icons/publishing_companies.svg';



const ICON_MAP = {
  photosIcon: 'fa:photo',
  documentsIcon: 'ph:file-bold',
  locationsIcon: 'ep:location',
  salesIcon: 'carbon:shopping-cart-arrow-down',
  salesAndAcquisitionsIcon: SalesAndAcquistionsIcon,
  artworksIcon : ArtworkIcon,
  main_infoIcon: 'iconamoon:home',
  textIcon: 'line-md:text-box',
  dimensionsIcon:'radix-icons:dimensions',
  setIcon:'heroicons-outline:collection',
  historyIcon:'ic:outline-history',
  fairsIcon:'material-symbols:festival',
  exhibitionsIcon: ExhibitionIcon,
  narrativeIcon: 'mdi:timeline-check-outline',
  artistsIcon: ArtistIcon,
  artStorageIcon: ArtStorageIcon,
  entitiesIcon: 'ic:outline-business',
  establishmentsIcon: 'lets-icons:shop-light',
  usersIcon: UserIcon,
  employeesIcon: 'clarity:employee-line',
  incoming_consignmentsIcon: Incoming_consignmentsIcon,
  outgoing_consignmentsIcon: Outgoing_consignmentsIcon,
  deliveriesIcon: DeliveriesIcon,
  addressesIcon: AddressesIcon,
  newsIcon: 'mdi:announcement-outline',
  selected_pressIcon: 'ph:newspaper-clipping',
  attached_imagesIcon: AttachedImagesIcon,
  attached_documentsIcon: AttachedDocsIcon,
  fairs_outpostsIcon : 'wpf:worldwide-location',
  emailsIcon: 'ic:baseline-alternate-email',
  phonesIcon:'bi:telephone',
  contactsIcon:"material-symbols:contact-mail-outline",
  acquisitionsIcon:"carbon:shopping-cart-arrow-up",
  artpartnersIcon:"material-symbols-light:handshake-outline",
  vendorsIcon:"guidance:cleaning-room",
  fleetIcon:FleetIcon,
  assetsIcon:"emojione-monotone:desktop-computer",
  financialsIcon:"fa6-solid:money-bill-trend-up",
  incoming_paymentsIcon:IPayIcon,
  outgoing_paymentsIcon:OPayIcon,
  permissionsIcon: 'icon-park-outline:unlock',
  final_invoicesIcon:"uil:file-lock-alt",
  journalistsIcon:JournalistsIcon,
  interestIcon: InterestOneIcon,
  interestManyIcon: InterestManyIcon,
  collectionIcon: CollectionIcon,
  collection_outsideIcon: CollectionOutIcon,
  hours_by_dayIcon:'mdi:store-clock-outline',
  hours_specific_dateIcon:'mdi:calendar-clock-outline',
  gmailIcon: 'streamline:gmail',
  proformaIcon: 'la:file-invoice-dollar',
  photographersIcon: 'pepicons-pop:studio-light-side',
  shippersIcon: 'la:shipping-fast',
  special_messagesIcon: 'mdi:comment-warning-outline',
  cratesIcon: 'game-icons:wooden-crate',
  kycIcon: 'fontisto:passport',
  mediasIcon: 'fluent-emoji-high-contrast:rolled-up-newspaper',
  videosIcon: 'circum:youtube',
  galleries_daily_reportsIcon: 'tdesign:calendar-2',
  single_visits_reportsIcon:'fa6-solid:people-pulling',
  artworks_createdIcon: Artworks_createdIcon,
  artworks_updatedIcon: Artworks_updatedIcon,
  contacts_updatedIcon: Contacts_updatedIcon,
  contacts_createdIcon: Contacts_createdIcon,
  artpartners_updatedIcon: Artpartners_updatedIcon,
  artpartners_createdIcon: Artpartners_createdIcon,
  artworks_soldIcon: Artworks_soldIcon,
  reservationsIcon: ReservationsIcon,
  artworks_favoritesIcon: Artworks_favoritesIcon,
  artworks_collectionIcon: Artworks_collectionIcon,
  publicationsIcon: 'mdi:book-open-page-variant-outline',
  publishing_collectionsIcon: 'hugeicons:books-01',
  Publishing_companiesIcon: Publishing_companiesIcon,
  extra_costsIcon: 'tdesign:money',
  keywordsIcon: 'mdi:hashtag',
  salesAlertIcon: 'tabler:mail-dollar',
  barcodeIcon: 'material-symbols:barcode'


};
const iconSize = { width: 24, height: 24 };
const iconSizeSvg = { width: 26, height: 26 };

export const getIconComponent = (iconName) => {
  const IconOrString = ICON_MAP[iconName];

  if (typeof IconOrString === 'string') {
    // Return a string-based icon (e.g., Iconify icon)
    return <Icon icon={IconOrString} style={iconSize} />;
  } else if (IconOrString) {
    // Return an SVG-based icon
    const SvgIcon = IconOrString;
    return <SvgIcon style={iconSizeSvg} />;
  }

  return null; // Return null if no icon found
};