import React, { useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { 
  Menu as MenuIcon, 
  QrCodeScanner as QrCodeScannerIcon, 
  WebAsset, 
  WebAssetOff
} from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';
import SearchInput from './SearchInput';
import StylePicker from './StylePicker';
import DarkModeToggle from './DarkModeToggle';
import { useCache } from '../../contexts/CacheContext';

const ResponsiveAppBar = memo(function ResponsiveAppBar() {
  const { userEmail, handleLogout } = useAuth();
  const theme = useTheme();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const { noCache, toggleCache } = useCache();

  const storedData = localStorage.getItem("StoredUserDataFromDb");
  let userData = {};
  try {
    userData = JSON.parse(storedData) || {};
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  const userId = localStorage.getItem("userId");
  const employeeId = userData?.employees_id;
  const userName = userData?.name;

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleLogoutClick = () => {
    handleCloseUserMenu();
    handleLogout();
  };

  const renderIconsMenu = (isMobile = false) => (
    <>
      <Link to="ScanApp" style={{ textDecoration: 'none', color: 'inherit' }}>
        {isMobile ? (
          <MenuItem onClick={handleCloseNavMenu} sx={{ py: 1 }}>
            <QrCodeScannerIcon sx={{ mr: 2 }} /> 
            <Typography>Scanner</Typography>
          </MenuItem>
        ) : (
          <IconButton sx={{ color: "white" }}>
            <QrCodeScannerIcon />
          </IconButton>
        )}
      </Link>

      {isMobile ? (
        <MenuItem onClick={() => { toggleCache(); handleCloseNavMenu(); }} sx={{ py: 1 }}>
          {noCache ? <WebAssetOff sx={{ mr: 2 }} /> : <WebAsset sx={{ mr: 2 }} />}
          <Typography>Cache {noCache ? "Disabled" : "Enabled"}</Typography>
        </MenuItem>
      ) : (
        <Tooltip title={noCache ? "Cache Disabled, click to enable" : "Cache Enabled, click to disable"}>
          <IconButton onClick={toggleCache} sx={{ color: "white" }}>
            {noCache ? <WebAssetOff /> : <WebAsset />}
          </IconButton>
        </Tooltip>
      )}

      {isMobile ? (
        <StylePicker isMobile onClick={handleCloseNavMenu} />
      ) : (
        <StylePicker />
      )}

      {isMobile ? (
        <DarkModeToggle isMobile onClick={handleCloseNavMenu} />
      ) : (
        <DarkModeToggle />
      )}
    </>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, height: 60 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{ mr: 2, display: { xs: "none", md: "flex" }, fontFamily: "monospace", fontWeight: 300, color: "inherit", textDecoration: "none" }}
          >
            CB Business Manager
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            <SearchInput navigate={navigate} />
          </Box>

          {/* Menu des icônes - version desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", gap: 2, mr: 2 }}>
            {renderIconsMenu()}
          </Box>

          {/* Menu des icônes - version mobile */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 2 }}>
            <IconButton
              size="small"
              aria-controls="icons-menu"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="icons-menu"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                sx: {
                  '& .MuiMenuItem-root': {
                    px: 2,
                  }
                }
              }}
            >
              {renderIconsMenu(true)}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userName} src="/static/images/avatar/1.jpg" sx={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Typography textAlign="right">Connected as {userEmail}</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="right">
                  <Link to={`users/detail/${userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>My User Profile</Link>
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="right">
                  <Link to={`employees/detail/${employeeId}`} style={{ textDecoration: 'none', color: 'inherit' }}>My Employee Profile</Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default ResponsiveAppBar;