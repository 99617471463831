import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  List,
  Paper,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import RefreshIcon from "@mui/icons-material/Refresh"; 
import dayjs from "dayjs";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getDisplayValue } from "../../utils/utils";
import { usePermissions } from "../../contexts/PermissionsContext";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateItem } from '../../api';
import { getFieldHelpText } from "../../utils/fieldHelpTexts";


const EditView = ({ section, item_data, modelData, handleEditClick, canModify=true }) => {
  const { permissions } = usePermissions();
  const [noCache, setNoCache] = useState(false);

  // Use the select options with the noCache flag
  const selectMappings = useSelectOptions(modelData.fields, noCache);




  // Setup the mutation to update the item using react-query
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ section, id, fieldKey, newValue }) =>
      updateItem(section, id, fieldKey, newValue),
    onSuccess: (data, variables) => {
      // Update the query cache with the new value
      queryClient.setQueryData(['items', section, { id: item_data.id }], (oldData) => {
        return {
          ...oldData,
          [variables.fieldKey]: variables.newValue,
        };
      });
    },
    onError: (error) => {
      // Handle error
      console.error("Error posting data:", error);
    },
  });


    // Handle refresh button click
    const handleRefresh = () => {
      queryClient.invalidateQueries(['items', section, { id: item_data.id }]);
    };

    


    const handleConversion = (conversion) => {
      const { fromFields, toFields, conversionValue } = conversion;
    
      if (conversionValue === "multiple") {
        const values = fromFields.map(field => parseFloat(item_data[field])).filter(v => !isNaN(v));
    
        if (values.length === fromFields.length) {
          let newValue = values.reduce((acc, val) => acc * val, 1);
    
          // Vérification du type de conversion
          if (toFields[0] === "area_sqm") {
            newValue /= 10000; // cm² → m²
          } else if (toFields[0] === "volume_cubic_meter") {
            newValue /= 1000000; // cm³ → m³
          }
    
          newValue = newValue.toFixed(3); // Arrondi à 3 décimales
    
          item_data[toFields[0]] = newValue;
          console.log("Multiplication newValue", newValue);
    
          mutation.mutate({
            section,
            id: item_data.id,
            fieldKey: toFields[0],
            newValue: newValue,
          });
        }
      } else {
        fromFields.forEach((fromField, index) => {
          const toField = toFields[index];
          if (item_data[fromField] !== undefined && !isNaN(item_data[fromField])) {
            const newValue = (parseFloat(item_data[fromField]) * conversionValue).toFixed(1);
    
            item_data[toField] = newValue;
            console.log("Conversion newValue", newValue);
    
            mutation.mutate({
              section,
              id: item_data.id,
              fieldKey: toField,
              newValue: newValue,
            });
          }
        });
      }
    };
    
    
  
  


  // Group fields by card organization
  const groupFieldsByCardOrganization = (fields) => {
    const groupedFields = {};
    fields.forEach((field) => {
      const cardOrg = field.cardOrganization || "Main Info";
      if (groupedFields[cardOrg]) {
        groupedFields[cardOrg].push(field);
      } else {
        groupedFields[cardOrg] = [field];
      }
    });
    return groupedFields;
  };

  return (
    <>

      {Object.entries(groupFieldsByCardOrganization(modelData.fields)).map(
        ([cardOrg, fields]) => {
          // Find conversion buttons related to this cardOrg
          const converterButtons = modelData.converterButtons?.filter(
            (converter) => converter.location === cardOrg
          );

          return (
            <Box key={cardOrg} sx={{ marginBottom: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2" sx={{ letterSpacing: 1, marginRight: 1 }}>
                {cardOrg}
              </Typography>
          
              {/* Ajout du Tooltip pour le cardOrganization */}
              {modelData.cardOrganizationHelp?.[cardOrg] && (
                <Tooltip title={modelData.cardOrganizationHelp[cardOrg]}>
                  <IconButton size="small" sx={{ marginLeft: 1 }}>
                    <InfoIcon sx={{ cursor: "help" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
          
              <Tooltip title="Refresh Data">
                <IconButton size="small" onClick={handleRefresh} aria-label="refresh">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          
              <Grid container spacing={2}>
                {fields.map((field) => {
                  if(field.key=="fk_column"){
                    field.key=section.replace("history_","")+"_id";
                    field.title=section.replace("history_","")+"_id";

                  }
                  if (field.showInDetail) {
                    if (field.fieldType === "text") {
                      return (
                        <Paper
                          className="text-color background-color"
                          elevation={0}
                          style={{
                            border: "1px dotted lightgray"
                          }}
                          key={field.key}
                          sx={{
                            width: field.paperWidth || "100%",
                            marginLeft: 2,
                            marginTop: 4,
                            p: 5,
                          }}
                        >
                          <Box
                          className="background-color"
                            sx={{

                              width: "100%",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h6" component="h6"  className="text-color">
                              {field.title.charAt(0).toUpperCase() +
                                field.title.slice(1)}
                            </Typography>
                            {field.editable &&
                              !item_data["is_file_closed"] &&
                              canModify && (
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  onClick={() => handleEditClick(field)}
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                          </Box>
                          <Typography
                            variant="body2"
                            component="p"
                            className="text-color"
                            dangerouslySetInnerHTML={{
                              __html: item_data[field.key],
                            }}
                          />
                        </Paper>
                      );
                    } else {
                      if (
                        field.showInDetailConditionNull &&
                        item_data[field.key] === null
                      ) {
                        return null;
                      } else {
                        const helpText= field?.helpText || getFieldHelpText(field.key) || null;
                        return (
                          <Grid item xs={4} key={field.key}>
                            <List sx={{ listStyleType: "none" }}>
                              <ListItem key={field.key}>
                                <ListItemText
                                  style={{ textTransform: "capitalize" }}
                                  primary={
                                    <span>
                                      {field.title.replace(/_/g, " ")}
                                      {helpText && (
                                        <Tooltip   title={helpText }>
                                          <IconButton
                                            size="small"
                                            style={{
                                              marginLeft: "5px",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <InfoIcon
                                              sx={{ cursor: "help" }}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </span>
                                  }
                                  secondary={getDisplayValue(
                                    field,
                                    item_data[field.key],
                                    selectMappings,
                                    true
                                  )}
                                />
                                {field.editable &&
                                  !item_data["is_file_closed"] &&
                                  canModify && (
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() =>
                                          handleEditClick(
                                            field,
                                            field?.conditionalFileLoad
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  )}
                              </ListItem>
                            </List>
                          </Grid>
                        );
                      }
                    }
                  }
                  return null;
                })}
              </Grid>


              {converterButtons && converterButtons.length > 0 && (
                <Box sx={{ marginTop: 2 }}>
                  {converterButtons.map((conversion, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleConversion(conversion)}
                      sx={{ marginRight: 1 }}
                    >
                      {conversion.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          );
        }
      )}
    </>
  );
};

export default EditView;
