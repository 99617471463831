import React, { useState, useEffect } from 'react';
import { 
  Radio, RadioGroup, FormControlLabel, Select, MenuItem, Button, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox
} from '@mui/material';
import { Link } from 'react-router-dom';

function LogisticsLabelsTable() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    zone: "",
    rack: "",
  });
  
  // États pour les éléments dynamiques
  const [zones, setZones] = useState(["A", "B", "C"]);
  const [racks, setRacks] = useState([1, 2, 3, 4, 5]);
  const [bays, setBays] = useState(["A", "B", "C"]);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [locations, setLocations] = useState([]);
  
  // Nouvel état pour suivre les cases complétées
  const [completedCells, setCompletedCells] = useState(new Set());

  // Fonction pour générer une clé unique pour chaque cellule
  const getCellKey = (bay, row) => `${bay}-${row}`;

  // Fonction pour basculer l'état complété d'une cellule
  const toggleCellCompletion = (bay, row) => {
    const cellKey = getCellKey(bay, row);
    setCompletedCells(prev => {
      const newSet = new Set(prev);
      if (newSet.has(cellKey)) {
        newSet.delete(cellKey);
      } else {
        newSet.add(cellKey);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/establishments.json`;
        const response = await fetch(fetchUrl);
        if (!response.ok) throw new Error("Failed to fetch data");
        const jsonData = await response.json();

        const activeLocations = jsonData
          .filter(est => est.is_active === 1)
          .map(est => ({ id: est.id, name: est.name, code: est.code }))
          .sort((a, b) => a.id - b.id);

        setLocations(activeLocations);
      } catch (error) {
        console.error("Error loading JSON data:", error);
      }
    };

    fetchData();
  }, []);

  // [Garder toutes les fonctions existantes : addZone, removeZone, addBay, etc.]
  const addZone = () => {
    const lastZone = zones[zones.length - 1];
    const nextZone = String.fromCharCode(lastZone.charCodeAt(0) + 1);
    setZones([...zones, nextZone]);
  };

  const removeZone = () => {
    if (zones.length > 1) {
      setZones(zones.slice(0, -1));
      if (selectedOptions.zone === zones[zones.length - 1]) {
        setSelectedOptions(prev => ({ ...prev, zone: "" }));
      }
    }
  };

  const addBay = () => {
    const lastBay = bays[bays.length - 1];
    const nextBay = String.fromCharCode(lastBay.charCodeAt(0) + 1);
    setBays([...bays, nextBay]);
  };

  const removeBay = () => {
    if (bays.length > 1) {
      setBays(bays.slice(0, -1));
    }
  };

  const addRack = () => {
    const nextRack = racks[racks.length - 1] + 1;
    setRacks([...racks, nextRack]);
  };

  const removeRack = () => {
    if (racks.length > 1) {
      setRacks(racks.slice(0, -1));
      if (selectedOptions.rack === racks[racks.length - 1].toString()) {
        setSelectedOptions(prev => ({ ...prev, rack: "" }));
      }
    }
  };

  const addRow = () => {
    const nextRow = rows[rows.length - 1] + 1;
    setRows([...rows, nextRow]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      setRows(rows.slice(0, -1));
    }
  };

  const getOrderedRows = () => {
    return [...rows].sort((a, b) => b - a);
  };

  // Fonction pour réinitialiser toutes les cases complétées
  const resetCompletedCells = () => {
    setCompletedCells(new Set());
  };

  return (
    <div>
      {/* [Garder tous les éléments de sélection existants...] */}
      <h4>Select Location</h4>
      <Select 
        fullWidth 
        value={selectedLocation} 
        onChange={(e) => setSelectedLocation(e.target.value)}
      >
        {locations.map((location) => (
          <MenuItem key={location.id} value={location.id}>
            {location.id} - {location.name} - {location.code}
          </MenuItem>
        ))}
      </Select>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <h4 style={{ marginBottom: 10 }}>Select Zone Label</h4>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <RadioGroup
            row
            value={selectedOptions.zone}
            onChange={(e) => setSelectedOptions(prev => ({ ...prev, zone: e.target.value }))}
          >
            {zones.map((zone) => (
              <FormControlLabel key={zone} value={zone} control={<Radio />} label={zone} />
            ))}
          </RadioGroup>
          </div>
          <div>
          <Button variant="contained" color="secondary" onClick={removeZone} style={{ marginRight: 8 }} disabled={zones.length <= 1}>
          -
            </Button>
            <Button variant="contained" color="primary" onClick={addZone} disabled={zones.length >= 10}>
             +
            </Button>

     
        </div>
      </div>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <h4 style={{ marginBottom: 10 }}>Select Rack</h4>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <RadioGroup
            row
            value={selectedOptions.rack}
            onChange={(e) => setSelectedOptions(prev => ({ ...prev, rack: e.target.value }))}
          >
            {racks.map((rack) => (
              <FormControlLabel key={rack} value={rack.toString()} control={<Radio />} label={rack} />
            ))}
          </RadioGroup>
          </div>
          <div>
          <Button variant="contained" color="secondary" onClick={removeRack} style={{ marginRight: 8 }} disabled={racks.length <= 1}>
             -
            </Button>
            <Button variant="contained" color="primary" onClick={addRack} disabled={racks.length >= 10}>
              +
            </Button>

       
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <h3>Rack Layout</h3>
          <div style={{ display: 'flex', gap: 10 }}>
            <div>
              <Button variant="contained" color="secondary" onClick={removeBay} style={{ marginRight: 8 }} disabled={bays.length <= 1}>
                Remove Bay
              </Button>
              <Button variant="contained" color="primary" onClick={addBay} >
                Add Bay
              </Button>
            </div>
            <div>
            <Button variant="contained" color="secondary" onClick={removeRow} style={{ marginRight: 8 }} disabled={rows.length <= 1}>
                Remove Row
              </Button>
              <Button variant="contained" color="primary" onClick={addRow} >
                Add Row
              </Button>
  
            </div>
            <Button 
              variant="outlined" 
              color="warning" 
              onClick={resetCompletedCells}
              style={{ marginLeft: 8 }}
            >
              Reset Progress
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Row</TableCell>
                {bays.map((bay) => (
                  <TableCell key={bay} align="center">Bay {bay}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getOrderedRows().map((row) => (
                <TableRow key={row}>
                  <TableCell component="th" scope="row">Row {row}</TableCell>
                  {bays.map((bay) => (
                    <TableCell 
                      key={bay} 
                      align="center"
                      style={{
                        backgroundColor: completedCells.has(getCellKey(bay, row)) ? '#e8f5e9' : 'inherit',
                        transition: 'background-color 0.3s'
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                        {selectedLocation && selectedOptions.zone && selectedOptions.rack && (
                          <>
                            <Link 
                              to={`/pdf/CL-${selectedLocation.toString().padStart(3, "0")}-${selectedOptions.zone}-${selectedOptions.rack}-${bay}-${row}/small/bin`} 
                              target="_blank"
                            >
                              <Button 
                                variant="outlined" 
                                size="small"
                                style={{
                                  backgroundColor: completedCells.has(getCellKey(bay, row)) ? '#81c784' : 'inherit'
                                }}
                              >
                                {selectedLocation.toString().padStart(3, "0")}-{selectedOptions.zone}-{selectedOptions.rack}-{bay}-{row}
                                
                              </Button>
                            </Link>
                            <Checkbox
                              checked={completedCells.has(getCellKey(bay, row))}
                              onChange={() => toggleCellCompletion(bay, row)}
                              size="small"
                            />
                          </>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default LogisticsLabelsTable;