import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { IconButton, Tooltip, MenuItem, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { updateItem } from '../../api';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const DarkModeToggle = ({ isMobile, onClick }) => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const userId = localStorage.getItem('userId');

  const updateDarkMode = useMutation({
    mutationFn: (newMode) => updateItem('users', userId, 'theme_mode', newMode),
    onSuccess: (data) => {
      console.log('Dark mode successfully updated:', data);
    },
    onError: (error) => {
      console.error('Error updating dark mode:', error);
    },
  });

  const handleToggle = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('StoredUserDataFromDb.theme_mode', newMode ? 'dark' : 'light');
    updateDarkMode.mutate(newMode ? 'dark' : 'light');
    console.log("StoredUserDataFromDb : ", localStorage.getItem('StoredUserDataFromDb.theme_mode'));

    // Si on est en mode mobile, fermer le menu
    if (isMobile && onClick) {
      onClick();
    }
  };

  if (isMobile) {
    return (
      <MenuItem onClick={handleToggle} sx={{ py: 1 }}>
        {darkMode ? <Brightness7Icon sx={{ mr: 2 }} /> : <Brightness4Icon sx={{ mr: 2 }} />}
        <Typography>
          {darkMode ? "Passer en mode clair" : "Passer en mode sombre"}
        </Typography>
      </MenuItem>
    );
  }

  return (
    <Tooltip title={darkMode ? "Activate Light Mode" : "Activate Dark Mode"}>
      <IconButton
        onClick={handleToggle}
        sx={{ color: 'inherit' }}
      >
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
};

export default DarkModeToggle;