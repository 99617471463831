import { useParams } from 'react-router-dom';
import LogisticsLabels from './LogisticsLabels';
import LogisticsLabelsTable from './LogisticsLabelsTable';

function LogisticsLabelsHome() {
  const { componentToCall } = useParams();

  let ComponentToRender;

  // Choisis le composant à afficher en fonction du paramètre
  switch (componentToCall) {
    case 'LogisticsLabels':
      ComponentToRender = LogisticsLabels;
      break;
    case 'LogisticsLabelsTable':
      ComponentToRender = LogisticsLabelsTable;
      break;
    default:
      ComponentToRender = () => <div>Page non trouvée</div>;
  }

  return <ComponentToRender />;
}

export default LogisticsLabelsHome;
