import React, { useState, useContext } from 'react';
import { Menu, MenuItem, IconButton, Typography } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { ThemeContext } from '../../contexts/ThemeContext';
import { updateItem } from "../../api";
import { useMutation } from "@tanstack/react-query";

const StylePicker = ({ isMobile, onClick }) => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = localStorage.getItem('userId');
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.stopPropagation(); // Prevent the parent MenuItem from closing the main menu
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateTheme = useMutation({
    mutationFn: ({ userId, theme }) => updateItem("users", userId, "theme", theme),
    onSuccess: (data) => {
      console.log("Theme successfully updated:", data);
      localStorage.setItem('StoredUserDataFromDb.theme', currentTheme);
      console.log("StoredUserDataFromDb : ", localStorage.getItem('StoredUserDataFromDb.theme'));
    },
    onError: (error) => {
      console.error("Error updating theme:", error);
    },
  });

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    updateTheme.mutate({ userId, theme });
    handleClose();
    if (isMobile && onClick) {
      onClick();
    }
  };

  const themeOptions = [
    { value: 'blue', label: 'Blue' },
    { value: 'dark_blue', label: 'Dark Blue' },
    { value: 'asse_green', label: 'Asse Green' },
    { value: 'strong_green', label: 'Strong Green' },
    { value: 'purple', label: 'Purple' }
  ];

  if (isMobile) {
    return (
      <>
        <MenuItem onClick={handleOpen} sx={{ py: 1 }}>
          <FormatPaintIcon sx={{ mr: 2 }} />
          <Typography>Change Style</Typography>
        </MenuItem>
        <Menu
          id="style-picker-menu-mobile"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {themeOptions.map((theme) => (
            <MenuItem 
              key={theme.value} 
              onClick={() => handleThemeChange(theme.value)}
            >
              {theme.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{ color: 'inherit' }}
        aria-controls={open ? 'style-picker-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open}
      >
        <FormatPaintIcon />
      </IconButton>
      <Menu
        id="style-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {themeOptions.map((theme) => (
          <MenuItem 
            key={theme.value} 
            onClick={() => handleThemeChange(theme.value)}
          >
            {theme.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StylePicker;