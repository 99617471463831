import React from 'react';
import { useParams } from 'react-router-dom';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import SmallLabels from './SmallLabels';  // Ton composant SmallLabels
import A4Labels from './A4Labels'; // Ton composant A4Labels
import { Button } from '@mui/material';

const LabelPDF = () => {
  const { label, size, type="bin" } = useParams();  // Récupère le label et la taille depuis l'URL
  
  // Découpe le label en différents éléments
  const [codeprefix, location, zone, rack, bay, shelf, bin] = label.split('-');

  // Définir le document en fonction de la taille
  const DocumentComponent = size === 'A4' ? A4Labels : SmallLabels;
  
  // Création du document PDF
  const pdfDoc = (
    <DocumentComponent
      type={type}
      location={location}
      zone={zone}
      rack={rack}
      bay={bay}
      shelf={shelf}
      bin={bin}
      fullLabel={label}
    />
  );

  // Fonction pour télécharger le PDF
  const handleDownload = async () => {
    const blob = await pdf(pdfDoc).toBlob();
    const url = URL.createObjectURL(blob);
    
    // Utilisation du label pour nommer le fichier
    const fileName = label ? `${encodeURIComponent(label)}.pdf` : 'label.pdf';


    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div style={{ height: '100vh', width: '100%', textAlign: 'center', paddingTop: 20 }}>
      <Button  style={{ marginBottom: 20 }} variant="outlined" color="primary"
        onClick={handleDownload} 
      >
        Download PDF
      </Button>
      <PDFViewer style={{ width: '100%', height: '90%' }}>
        {pdfDoc}
      </PDFViewer>
    </div>
  );
};

export default LabelPDF;
