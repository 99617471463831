import React, { useState, useEffect } from 'react';
import useAuth from './hooks/useAuth';
import { PermissionsProvider } from './contexts/PermissionsContext'; 
import UserMainContent from './UserMainContent';
import Login from "./components/Login/Login";
import Loading from "./components/Loading/Loading";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import ThemeProvider from './contexts/ThemeContext';
import { CacheProvider } from './contexts/CacheContext';

// Configure Amplify at the app level
Amplify.configure(awsmobile);



function App() {
  const { user, authLoading, authOkay, permissions} = useAuth(); // Remove setUser since it's not needed
  const [readyToShowLogin, setReadyToShowLogin] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      const timer = setTimeout(() => setReadyToShowLogin(true), 500);
      return () => clearTimeout(timer);
    }
  }, [authLoading]);

  // Add error boundary
  useEffect(() => {
    const handleError = (error) => {
      console.error('Auth Error:', error);
    };

    window.addEventListener('unhandledrejection', handleError);
    return () => window.removeEventListener('unhandledrejection', handleError);
  }, []);

  return (
    <CacheProvider>
    <PermissionsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
        <div>

          {authLoading || !readyToShowLogin ? (
            <Loading/>
          ) : authOkay ? (
            <UserMainContent permissions={permissions}/>
          ) : (
            <Login /> // Remove setUser prop
          )}
        </div>
        </ThemeProvider>
      </LocalizationProvider>
    </PermissionsProvider>
    </CacheProvider>
  );
}

export default App;
