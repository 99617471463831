import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Help({ helpData }) {
  const [dataToMap, setDataToMap] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}help_texts/help_texts_${helpData}_related.json?123`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setDataToMap(data);
      } catch (error) {
        console.error("Failed to fetch data for", helpData, error);
      }
    };
    fetchData();
  }, [helpData]);

  return (
    <div style={{ 
      width: '100%',
      minWidth: '200px',
      position: 'relative'
    }}>
      <div style={{
        width: '100%',
        overflow: 'hidden'
      }}>
        {dataToMap?.map((item, index) => (
          <>
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '60vw', overflow: 'hidden' }}>
              <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>

</>
        ))}
        {dataToMap?.length >0 && <Accordion key="hello_it">
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls={`panel_itcrowd`}
id={`panel_itcrowd`}
>
<Typography style={{ width: '60vw', wordWrap: 'break-word' }}>
  But it still does not work!
</Typography>
</AccordionSummary>
<AccordionDetails style={{ width: '60vw', overflow: 'hidden' }}>
<Typography style={{ width: '60vw', wordWrap: 'break-word' }}>
  <img src="https://cb-apps-global-data-mapping.s3.eu-west-3.amazonaws.com/help_texts/hello-it.gif"></img><br/>
  If you want to report a bug, please be very specific and include the URL of this page with your request, and обязательно a screenshot with, if necessary, an indication of where the bug is located. On Mac, with QuickTime Player, it is very easy to record your screen for a few seconds. This will be of great help to me in resolving this bug.

</Typography>
</AccordionDetails>
</Accordion>}
      </div>
    </div>
  );
}

export default Help;