import { useState, useEffect } from 'react';
import axios from 'axios';

function useS3Operations(options) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { action, key, forceDownload, shouldExecute, tagKey, tagValue, nameEndFilter, bucket="cb-business-manager", listSubfolders=false} = options;

  useEffect(() => {
    if (!key || !shouldExecute || !action) return;
    console.log(' action shouldExecute key', action, shouldExecute, key);

    const performS3Operation = async () => {
      let url = `${process.env.REACT_APP_APIGETAWAY_S3_URL}?bucket=${bucket}&action=${action}&key=${key}`;
      console.log('hooks has been called', options);
      if (forceDownload) {
        url += `&forceDownload=${forceDownload}`;
      }

      if (tagKey && tagValue) {
        url += `&tagKey=${tagKey}&tagValue=${tagValue}`;
      }

      if (nameEndFilter) {
        url += `&nameEndFilter=${nameEndFilter}&listSubfolders=false`;
      }

      if (listSubfolders) {
        url += `&listSubfolders=true`;
      }

      try {
        setLoading(true);
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('CognitoJWTToken')}`
          }
        });

        setData(response.data);

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    performS3Operation();
  }, [action, key, forceDownload, shouldExecute, tagKey, tagValue]);
  if(data){
console.log("data from hook", data)
  }
  return { data, setData, loading, error };
}

export default useS3Operations;
