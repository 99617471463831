import React, { useState, useEffect } from 'react';
import { Button, Select, InputLabel, FormControl, MenuItem, TextField } from '@mui/material';
import {useMutation} from '@tanstack/react-query'
import { loadItems } from '../../api';
import { all } from 'axios';


function MakeMultiplePublicJson() {
  const [fields, setFields] = useState('id,name'); 


  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

const all_section = ['exhibitions','artists']
  return (
 <div>
          {all_section.map((section) => (
             <div>
            <Button sx={{width: '100%'}} variant="outlined" size="medium" color="primary" >
            Generate {section} Json
        </Button>
        <br/>
        <br/>
        </div>
          ))}
          
          
          
        
  </div>
  );
}

export default MakeMultiplePublicJson;