import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Select, MenuItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function LogisticsLabels() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    zones: new Set(),
    racks: new Set(),
    bays: new Set(),
    rows: new Set(),
    bins: new Set(),
  });

  const [zones, setZones] = useState(["A", "B", "C"]);
  const [racks, setRacks] = useState([1, 2, 3, 4, 5]);
  const [bays, setBays] = useState(["A", "B", "C"]);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [bins, setBins] = useState(["A", "B", "C"]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/establishments.json`;
        const response = await fetch(fetchUrl);
        if (!response.ok) throw new Error("Failed to fetch data");
        const jsonData = await response.json();

        // Filtrer uniquement les établissements actifs et stocker id + name
        const activeLocations = jsonData
          .filter(est => est.is_active === 1)
          .map(est => ({ id: est.id, name: est.name, code: est.code }))
          .sort((a, b) => a.id - b.id);

        setLocations(activeLocations);
      } catch (error) {
        console.error("Error loading JSON data:", error);
      }
    };

    fetchData();
  }, []);






  const handleToggle = (category, value) => {
    setSelectedOptions((prev) => {
      const updatedSet = new Set(prev[category]);
      updatedSet.has(value) ? updatedSet.delete(value) : updatedSet.add(value);
      return { ...prev, [category]: updatedSet };
    });
  };
  const generateLabels = () => {
    if (!selectedLocation) return [];
  
    const locationId = selectedLocation.toString().padStart(3, "0");
    let labels = [];
  
    // Ajouter le label de location seule
    labels.push({ type: "location", label: `CL-${locationId}` });
  
    // Si une zone est cochée, ajouter les labels correspondants
    selectedOptions.zones.forEach((zone) => {
      labels.push({ type: "zone", label: `CL-${locationId}-${zone}` });
  
      // Si des racks sont cochés, ajouter les labels correspondants
      selectedOptions.racks.forEach((rack) => {
        labels.push({ type: "rack", label: `CL-${locationId}-${zone}-${rack}` });
  
        // Si des baies sont cochées, ajouter les labels correspondants
        selectedOptions.bays.forEach((bay) => {
          labels.push({ type: "bay", label: `CL-${locationId}-${zone}-${rack}-${bay}` });
          selectedOptions.rows.forEach((row) => {
            labels.push({ type: "shelf", label: `CL-${locationId}-${zone}-${rack}-${bay}-${row}` });
            if (selectedOptions.bins.size > 0) {
              // Si des bins sont cochés, ajouter les labels complets avec bin
              selectedOptions.bins.forEach((bin) => {
                labels.push({
                  type: "bin",
                  label: `CL-${locationId}-${zone}-${rack}-${bay}-${row}-${bin}`,
                });
              });
            } else {
              // Sinon, ajouter les labels sans bin
             /* labels.push({
                type: "bin",
                label: `CL-${locationId}-${zone}-${rack}-${bay}-${row}`,
              });*/
            }
          });
        });
      });
    });
  
    return labels;
  };

  const renderCheckboxes = (category, items, setItems) => (
    <div>
      <h4>Select {category.charAt(0).toUpperCase() + category.slice(1)}</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
        {items.map((item) => (
          <FormControlLabel
            key={`${category}-${item}`}
            control={
              <Checkbox
                checked={selectedOptions[category].has(item)}
                onChange={() => handleToggle(category, item)}
              />
            }
            label={item}
          />
        ))}
      </div>
      <div style={{ marginTop: 5 }}>
        <Button variant="contained" color="secondary" onClick={() => setItems(items.slice(0, -1))} disabled={items.length <= 1}>
          −
        </Button>
        <Button variant="contained" onClick={() => setItems([...items, String.fromCharCode(String(items[items.length - 1]).charCodeAt(0) + 1)])} style={{ marginLeft: 5 }}>
          +
        </Button>
      </div>
    </div>
  );
  

  return (
    <div>
      <h4>Select Location</h4>
      <Select 
        fullWidth 
        value={selectedLocation} 
        onChange={(e) => setSelectedLocation(e.target.value)}
      >
        {locations.map((location) => (
          <MenuItem key={location.id} value={location.id}>
           {location.id} - {location.name}  - {location.code}
          </MenuItem>
        ))}
      </Select>

      {renderCheckboxes("zones", zones, setZones)}
      {renderCheckboxes("racks", racks, setRacks)}
      {renderCheckboxes("bays", bays, setBays)}
      {renderCheckboxes("rows", rows, setRows)}
      {renderCheckboxes("bins", bins, setBins)}

      <div>
  <h3>Generated Labels:</h3>
  {generateLabels().map((labelInfo, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}>
      <span>
        <strong>{labelInfo.type}:</strong> {labelInfo.label}
      </span>
      {labelInfo.type!="zone" && labelInfo.type!="location" && labelInfo.type!="rack" && labelInfo.type!="bay" && <Link to={`/pdf/${labelInfo.label}/small/${labelInfo.type}`} target="_blank">
        <Button variant="outlined" size="small">small PDF</Button>
      </Link>}
      <Link to={`/pdf/${labelInfo.label}/A4/${labelInfo.type}`} target="_blank">
        <Button variant="outlined" size="small">A4 PDF</Button>
      </Link>
    </div>
  ))}
</div>
    </div>
  );
}

export default LogisticsLabels;
