import React from 'react';
import { Document, Page, View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

// Fonction pour générer le code-barres
const generateBarcode = (value) => {
  let canvas = document.createElement("canvas");
  JsBarcode(canvas, value, {
    format: 'CODE128',
    displayValue: false,
    height: 30, // Diminuer la hauteur du code-barres
    width: 2.5,   // Augmenter l'épaisseur des barres
  });
  return canvas.toDataURL();
};

// Définition des styles
const styles = StyleSheet.create({
  page: {
    width: '90mm',
    height: '38mm',
    padding: 5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  barcode: {
    width: 60,  // Diminuer la largeur du code-barres
    height: 15, 
  },
  label: {
    fontSize: 8,  // Diminuer la taille de la police du label
    textAlign: 'center',
    paddingHorizontal: 2, // Add horizontal padding to center the label
  },
  arrow: {
    width: 8, 
    height: 20,
    marginHorizontal: 2,
  },
});

// Le document PDF
const SmallLabels = ({ fullLabel }) => {
  const barcodeData = generateBarcode(fullLabel);

  return (
    <Document>
      {/* Page 1: Flèches vers le haut */}
      <Page size={{ width: 90, height: 38 }} style={styles.page}>
        <View style={styles.container}>
          <Image source={require('./arrow-down.png')} style={styles.arrow} /> 
          <View>
            <Image src={barcodeData} style={styles.barcode} />
            <Text style={styles.label}>{fullLabel}</Text>
          </View>
          <Image source={require('./arrow-down.png')} style={styles.arrow} />
        </View>
      </Page>

      {/* Page 2: Flèches vers le bas */}
      <Page size={{ width: 90, height: 38 }} style={styles.page}>
        <View style={styles.container}>
          <Image source={require('./arrow-up.png')} style={styles.arrow} />
          <View>
            <Image src={barcodeData} style={styles.barcode} />
            <Text style={styles.label}>{fullLabel}</Text>
          </View>
          <Image source={require('./arrow-up.png')} style={styles.arrow} />
        </View>
      </Page>
    </Document>
  );
};

export default SmallLabels;